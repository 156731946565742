import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/second-chance.scss"

const SmileAgainProgram = () => {
  return (
    <Layout>
      <SEO
        title="Smile Again Program"
        description="It is time for a brand new, healthy, beautiful smile at Beverly Hills Oral Maxillofacial Surgery & Dental Implants."
      />

      <div className="sas">
        <div className="sas__hero sas__section">
          <img
            className="sas__hero-img--desktop"
            src="/BHOMS/DEV/smile-again-hero-image.png"
            alt="smile again banner"
          />
          <img
            className="sas__hero-img--mobile"
            src="/BHOMS/DEV/smile-again-hero-image-mobile.png"
            alt="smile again banner"
          />
        </div>

        <div className="sas__body sas__section">
          <div className="sas__container small">
            <h1>An Opportunity to Smile Again</h1>

            <p>
              Beverly Hills Oral Maxillofacial Surgery & Dental Implants is
              proud to announce its first Smile Again program. This
              life-changing program provides one deserving recipient with a
              complete smile makeover free of charge.
            </p>

            <p>
              The ideal candidate has multiple missing or failing teeth and
              cannot afford restorative treatment. After the submission period,
              we will select one recipient to receive a free{" "}
              <Link
                to="/full-arch-restoration/"
                title="Learn more about full-arch restoration">
                full-arch restoration
              </Link>{" "}
              procedure. We encourage anyone in our community who is dreaming of
              a healthy, beautiful new smile to apply!
            </p>

            <h3>Full-Arch Restoration</h3>

            <p>
              Full-arch restoration is a healthy and effective way to replace
              many missing teeth. The procedure works by securing a full
              prosthesis in the mouth using as few as four dental implants. The
              result is a complete smile restoration that looks and feels
              natural.
            </p>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container">
            <div className="sas__brackets">
              <div className="sas__about-grid">
                <div>
                  <p className="sas__subheading">How To Apply</p>
                  <ul>
                    <li>Complete the short application.</li>
                    <li>Upload photos of your smile and teeth.</li>
                    <li>Submit your application by September 21, 2023.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container small">
            <p>
              <strong>
                Thank you for your interest in the Smile Again program. The
                application period is now closed. Applicants will be notified
                once the selection process has been completed.
              </strong>
            </p>
            <p>
              Stay tuned to this page,{" "}
              <a
                href="https://www.facebook.com/people/Beverly-Hills-Oral-Maxillofacial-Surgery-Institute/100063546190091/"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/beverlyhillsoralsurgeon/?hl=en"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>{" "}
              for program updates!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/dental-implants-beverly-hills-ca/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/full-arch-restoration/"
                  title="Learn more about full-arch-restoration">
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmileAgainProgram
